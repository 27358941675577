.wrapper {
  &.isPdp p {
    font-size: 12px;
  }

  & p {
    margin: 0;
  }
}

.feeText {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
}
