.modalContent {
  padding: 32px 0;
}

.modalIntro {
  margin: 0 0 20px 0;
}

.categoriesSubtitle {
  margin: 0 0 24px 0;
}

.subtitle {
  margin: 12px 0 0 0;
  @media (--tablet) {
    margin: 0;
  }
}

.content {
  margin: 0 0 40px 0;
}

.categoryContainer {
  @media (--tablet) {
    display: flex;
    align-items: flex-start;
    gap: 78px;

    & > :first-child {
      top: 0;
      flex-shrink: 0;
      flex-basis: 120px;
    }
  }
}

.categoryText {
  @media (--tablet) {
    display: flex;
    flex-direction: column;
  }
}
