.priceRating {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: auto;
  max-width: 150px;
  padding: 0;
  margin: 0;
  top: -5px;
}

.gauge {
  position: relative;
  right: -6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  max-width: 28px;

  & svg {
    width: 100%;
  }
}

.scoreArrow {
  display: flex;
  transform: rotate(-90deg);
  position: absolute;
  top: 5px;
  left: 14.5px;
  width: 4px;
  transition: transform 1s cubic-bezier(0.73, -0.06, 0.4, 1.37);

  &.fair {
    transform: rotate(-45deg);
    left: 9px;
  }

  &.good {
    transform: rotate(0deg);
    top: 4px;
    left: 12px;
  }
  &.great {
    transform: rotate(45deg);
    left: 15px;
  }

  &[data-experiment='true'] {
    &.fair {
      transform: rotate(-45deg);
      left: 9px;
    }

    &.good {
      transform: rotate(0deg);
      top: 4px;
      left: 12px;
    }
    &.great {
      transform: rotate(45deg);
      left: 15px;
    }
  }
}

.label {
  color: var(--black);
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  padding: 2px var(--spacing-1) var(--spacing-1) 8px;
  position: relative;
  top: 1px;
  z-index: 0;
  border-radius: 0 var(--spacing-1) var(--spacing-1) 0;
  white-space: nowrap;

  &:first-letter {
    text-transform: capitalize;
  }

  &.fair {
    background-color: var(--warning-200);
  }

  &.good {
    background-color: var(--color-heycar-mint-200);
  }

  &.great {
    background-color: var(--color-heycar-mint-400);
  }

  &.experiment {
    background-color: var(--color-sunbeam-blue-300);
  }
}

.circle {
  &.fair {
    fill: var(--warning-400);
  }

  &.good {
    fill: var(--color-heycar-mint-400);
  }

  &.great {
    fill: var(--color-heycar-mint-600);
  }
}

.clickable {
  cursor: pointer;
}
